import React, { useState, useEffect } from "react";
import "../styles.css";

const Slider = ({
  images = [],
  autoPlay = true,
  autoPlayTime = 8000,
  children,
  ...props
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const showNextSlide = (slideIndex = currentSlide + 1) => {
    const newSlideIndex = slideIndex >= images.length ? 0 : slideIndex;

    setCurrentSlide(newSlideIndex);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      showNextSlide();
    }, autoPlayTime);

    return () => clearTimeout(timer);
  }, [currentSlide]);

  return (
    <div className="slider-wrapper" {...props}>
      {images.map((imageUrl, index) => (
        <div
          className="slide"
          key={index}
          style={{
            backgroundImage: `url(${imageUrl})`,
            marginLeft: index === 0 ? `-${currentSlide * 100}%` : undefined,
          }}
        ></div>
      ))}
      <div className="children-wrapper">{children}</div>
    </div>
  );
};

export default Slider;
