import React from "react";
import "../styles.css";

const Section = ({ title, postscriptum = null, children }) => {
  return (
    <div className="section-container">
      <div className="section-header">
        <h2>{title}</h2>
      </div>
      <div className="section-children">{children}</div>
      <div className="section-postscriptum">
        <p>{postscriptum}</p>
      </div>
    </div>
  );
};

export default Section;
