import React from "react";
import { FaFacebook } from "react-icons/fa";
import "../styles.css";

const Navbar = ({ showMainContent, showPriceList, showContactInfo }) => {
  return (
    <div className="navbar-container">
      <div className="navbar-item" style={{ display: "none" }}>
        {/* O nas */}
      </div>
      <div className="navbar-item" onClick={showMainContent}>
        O nas
      </div>
      <div className="navbar-item" onClick={showPriceList}>
        Cennik
      </div>
      <div className="navbar-item" onClick={showContactInfo}>
        Kontakt
      </div>
      <a href="https://www.facebook.com/bus.majster/" target="_blank">
        <div className="navbar-item">
          <FaFacebook size={"1.4em"} />
        </div>
      </a>
    </div>
  );
};

export default Navbar;
