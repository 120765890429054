import React, { useState } from "react";
import Header from "../components/Header";
import Section from "../components/Section";
import Navbar from "../components/Navbar";
import { MdOutlineMailOutline } from "react-icons/md";
import { FiFacebook } from "react-icons/fi";
import { BsTelephoneInbound } from "react-icons/bs";
import { FiHome } from "react-icons/fi";
import { MdPermIdentity } from "react-icons/md";
import "../styles.css";

const MainPage = () => {
  const [isMainContentRendered, setIsMainContentRendered] = useState(true);
  const [isPriceListRendered, setIsPriceListRendered] = useState(false);
  const [isContactInfoRendered, setIsContactRendered] = useState(false);

  const renderMainContent = (
    <>
      <Section
        title={"O NAS"}
        postscriptum={
          "Ponadto zgodnie z Państwa życzeniem obsługujemy inne trasy na terenie województwa podkarpackiego i całego kraju."
        }
      >
        Oferujemy zgodnie z posiadaną licencją, usługi w zakresie krajowego
        przewozu osób dla grup zorganizowanych oraz klientów indywidualnych.
        Usługi te realizujemy głównie na terenie Bieszczad pod kątem dowozu na
        szlaki turystyczne.
        <br />
        <br />
        Tel. 606 336 019 / 728 309 484
        <p>
          Możliwość zapewnienia transportu powrotnego do miejsc noclegowych.
        </p>
      </Section>
      <Section title={"OFERTA"}>
        <b>W zakresie transportu oferujemy obsługę:</b>
        <ul>
          <li>Spotkań firmowych i biznesowych,</li>
          <li>Imprez plenerowych,</li>
          <li>Imprez okolicznościowych (wesela, chrzciny, zabawy itp.),</li>
          <li>Koncertów, konferencji,</li>
          <li>Zawodów sportowych,</li>
          <li>
            Transferów lotniskowych (Rzeszów Jasionka oraz Kraków Bailce),
          </li>
          <li>Wyjazdów wielodniowych,</li>
          <li>Organizacja licencjonowanego przewodnika.</li>
        </ul>
      </Section>
      <Section title={"USŁUGI"}>
        <b>Poza przewozem osób oferujemy usługi:</b>
        <ul>
          <li>Stolarskie,</li>
          <li>Ogrodowe,</li>
          <li>Glazurnicze, malarskie,</li>
          <li>Odśnieżanie, koszenie traw</li>
        </ul>
      </Section>
    </>
  );

  const renderPriceList = (
    <Section title={"CENNIK"}>
      <p>
        Cena za realizowaną usługę uzgadniana jest indywidualnie. Uzależniona
        jest od długości trasy, ilości osób i czasu wynajmu.Za wykonanie usługi
        wystawiam paragon z kasy fiskalnej lub rachunek.
      </p>
    </Section>
  );

  const renderContactInfo = (
    <Section title={"KONTAKT"}>
      <div className="contact-header">
        <h3>
          <b>"Bus-Majster" Marek Jurek</b>
        </h3>
      </div>
      <div className="contact-line">
        <div className="contact-line-header">
          <h4>Adres</h4>
        </div>
        <div className="contact-line-description">
          <FiHome /> &nbsp; Wetlina 39/2
        </div>
      </div>
      <div className="contact-line">
        <div className="contact-line-header">
          <h4>Identyfikatory</h4>
        </div>
        <div className="contact-line-description">
          NIP: 688-106-08-20,
          <br />
          REGON: 180958657
        </div>
      </div>
      <div className="contact-line">
        <div className="contact-line-header">
          <h4>Email</h4>
        </div>
        <div className="contact-line-description">
          <MdOutlineMailOutline /> &nbsp; busmajster@gmail.com
        </div>
      </div>
      <div className="contact-line">
        <div className="contact-line-header">
          <h4>facebook</h4>
        </div>
        <div className="contact-line-description">
          <FiFacebook /> &nbsp;{" "}
          <a href="https://www.facebook.com/bus.majster/" target="_blank">
            https://www.facebook.com/bus.majster/
          </a>
        </div>
      </div>
      <div className="contact-line">
        <div className="contact-line-header">
          <h4>telefon</h4>
        </div>
        <div className="contact-line-description">
          <BsTelephoneInbound /> &nbsp; 606 336 019 / 728 309 484
        </div>
      </div>
    </Section>
  );

  const showPriceList = () => {
    setIsPriceListRendered(true);
    setIsMainContentRendered(false);
    setIsContactRendered(false);
  };

  const showContactInfo = () => {
    setIsContactRendered(true);
    setIsPriceListRendered(false);
    setIsMainContentRendered(false);
  };

  const showMainContent = () => {
    setIsMainContentRendered(true);
    setIsPriceListRendered(false);
    setIsContactRendered(false);
  };

  return (
    <div className="main-container">
      <Header>BUS - MAJSTER</Header>
      <div className="navbar-wrapper">
        <div className="under-header">
          <h3>Przewóz osób w Bieszczadach</h3>
        </div>
        <Navbar
          showPriceList={showPriceList}
          showContactInfo={showContactInfo}
          showMainContent={showMainContent}
        />
      </div>
      <div className="sections-wrapper">
        {isMainContentRendered && renderMainContent}
        {isPriceListRendered && renderPriceList}
        {isContactInfoRendered && renderContactInfo}
      </div>
    </div>
  );
};

export default MainPage;
