import React from "react";
import MainPage from "./pages/MainPage";
import Header from "./components/Header";
import Slider from "./components/Slider";
import "./styles.css";

const App = () => {
  const imagesUrl = [
    "/images/slide_02.JPG",
    "/images/slide_03.JPG",
    "/images/slide_04.JPG",
    "/images/slide_05.JPG",
    "/images/slide_06.JPG",
    "/images/slide_07.JPG",
  ];

  return (
    <Slider images={imagesUrl}>
      <MainPage />
    </Slider>
  );
};

export default App;
